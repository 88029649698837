import "./selectField.css";
export default function Select(props) {
  return (
    <div className="field-container">
      <label>{props.label}</label>
      <select
        className="selectField"
        name={props.name}
        type={props.type}
        onChange={props.onChange}
        value={props.value}
      >
        <option value="111">
          White
        </option>
        {/* <option value="#000">Dark</option> */}
      </select>
    </div>
  );
}
