// src/components/FakeSlice.js
import React, { useEffect } from "react";
import "./fakeSlice.css";
import { FieldContext } from "../form/context";

export default function FakeSlice() {
  const context = React.useContext(FieldContext);
  const btnColor = context.form.btn_color.value;
  const resultColor = context.form.result_color.value;


  const resultStyle = {
    backgroundColor: btnColor,
  };
  const resultBgStyle = {
    backgroundColor: resultColor,
  };


  useEffect(() => {
    const form = jQuery(".form_bmi2");

    form.on("submit", function (e) {
      e.preventDefault();
      const resultDiv = jQuery(".result .title");
      jQuery('.calc_result').hide();
      jQuery('.result').addClass('active');
      // jQuery('html, body').animate({ scrollTop: jQuery('#result').offset().top }, 1000);

      function calcBMI() {
        const weight = jQuery("#weight").val();
        const weightInKg = weight / 2.205;
        const ft = jQuery("#height_ft").val();
        const inch = jQuery("#height_in").val();
        const inchInMeters = inch / 12;
        const heightInFeet = parseInt(ft) + parseFloat(inchInMeters);
        const heightInMeters = heightInFeet / 3.281;
        const bmi = weightInKg / (heightInMeters * heightInMeters);
        return bmi.toFixed(2);
      }

      function bmiState() {
        const bmi = calcBMI();
        if (bmi <= 16.9) {
          return "Underweight";
        }
        if (bmi > 16.9 && bmi <= 18.49) {
          return "Underweight";
        }
        if (bmi > 18.49 && bmi <= 24.9) {
          return "Healthy";
        }
        if (bmi > 24.9 && bmi <= 29.9) {
          return "Overweight";
        }
        if (bmi > 29.9 && bmi <= 34.9) {
          return "Obesity Class I";
        }
        if (bmi > 34.9 && bmi <= 40) {
          return "Obesity Class II";
        }
        if (bmi > 40) {
          return "Obesity Class III";
        }
      }

      resultDiv.html("Your BMI is " + calcBMI() + " (" + bmiState() + ")");
    });
  }, []); // Empty dependency array ensures that the effect runs once after the initial render

  function createMarkup() {
    return {
      __html: context.form.text.value
    };
  }

  function createMarkupText2() {
    return {
      __html: context.form.text2.value
    };
  }
  const rgbValues = context.form.bg.value.match(/\d+/g);
  const isBlackBackground = rgbValues && rgbValues.every((value) => parseInt(value, 10) === 0);


  return (
    <div
      className={`sliceSimulator ${isBlackBackground ? "dark-text" : ""}`}
      style={{ backgroundColor: context.form.bg.value }}
    >

      <div className="text">
        <h1>{context.form.title.value}</h1>
        <div dangerouslySetInnerHTML={createMarkup()} />
        <form className="box form_bmi2">
          <div className="row jc-sb">
            <div className="col">
              <div className="field field2">
                <input id="height_ft" className="input" type="number" placeholder="ft*" required="yes" />
                <div className="delimeter"></div>
                <input id="height_in" className="input" type="number" placeholder="in*" required="yes" />
                <div className="delimeter"></div>
              </div>
            </div>
            <div className="col">
              <div className="field">
                <input id="weight" className="input" type="number" placeholder="lbs*" required="yes" />
              </div>
            </div>
          </div>
            <div className="col">
              <input className="btn goto is-link is-outlined button-like"  style={resultStyle} type="submit" value="Calculate Now" />
            </div>
        </form>
        <div id="result"></div>
        <div className="result" style={resultBgStyle}>
          <h2 className="title"></h2>
        </div>
        <section>
        <div class="ast-container bm-box bm-box_2" dangerouslySetInnerHTML={createMarkupText2()}></div>
    </section>
      </div>
    </div>
  );
}
