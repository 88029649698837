// SuccessPage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import "./slice.css";
import { FieldContext } from "./components/form/context";

const SuccessPage = () => {
  const context = React.useContext(FieldContext);
  const [data, setData] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://bmi.doctorsgoviral.com/api/detail/${id}/`);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const form = jQuery(".form_bmi2");

    form.on("submit", function (e) {
      e.preventDefault();
      const resultDiv = jQuery(".result .title");
      jQuery('.calc_result').hide();
      jQuery('.result').addClass('active');

      function calcBMI() {
        const weight = jQuery("#weight").val();
        const weightInKg = weight / 2.205;
        const ft = jQuery("#height_ft").val();
        const inch = jQuery("#height_in").val();
        const inchInMeters = inch / 12;
        const heightInFeet = parseInt(ft) + parseFloat(inchInMeters);
        const heightInMeters = heightInFeet / 3.281;
        const bmi = weightInKg / (heightInMeters * heightInMeters);
        return bmi.toFixed(2);
      }

      function bmiState() {
        const bmi = calcBMI();
        if (bmi <= 16.9) {
          return "Underweight";
        }
        if (bmi > 16.9 && bmi <= 18.49) {
          return "Underweight";
        }
        if (bmi > 18.49 && bmi <= 24.9) {
          return "Healthy";
        }
        if (bmi > 24.9 && bmi <= 29.9) {
          return "Overweight";
        }
        if (bmi > 29.9 && bmi <= 34.9) {
          return "Obesity Class I";
        }
        if (bmi > 34.9 && bmi <= 40) {
          return "Obesity Class II";
        }
        if (bmi > 40) {
          return "Obesity Class III";
        }
      }

      // Check if data is available before accessing properties
      if (data) {
        resultDiv.html("Your BMI is " + calcBMI() + " (" + bmiState() + ")");
      }
    });
  }, [context.form, data]); // Include context.form and data in the dependency array

  function createMarkup() {
    return {
      __html: context.form.text.value
    };
  }

  function createMarkupText2() {
    return {
      __html: context.form.text2.value
    };
  }

const rgbValues = data?.background_color?.match(/\d+/g);
const isBlackBackground = rgbValues && rgbValues.every((value) => parseInt(value, 10) === 0);

const btnColor = data ? data.btn_color : "#000000";
const resultBgColor = data ? data.result_color : "#000000";

const resultBgStyle = {
  backgroundColor: resultBgColor,
  // other styling properties as needed
};

const btnColorStyle = {
  backgroundColor: btnColor,
  // other styling properties as needed
};



// ... (previous code)

return (
<div
    className={`sliceSimulator ${isBlackBackground ? "dark-text" : ""}`}
    style={{
      backgroundColor: data ? data.background_color : 'default-color',
      maxHeight: '100%',
      maxWidth: '100%',
    }}
  >
          <div className="text">
        {/* Check if data is available before accessing properties */}
        <h1>{data ? data.title : "Loading..."}</h1>
  
        {/* Check if data is available before accessing properties */}
        <div dangerouslySetInnerHTML={{ __html: data ? data.description1 : "Loading..." }} />
  
        <form className="box form_bmi2">
          <div className="row jc-sb">
            <div className="col">
              <div className="field field2">
                <input id="height_ft" className="input" type="number" placeholder="ft*" required="yes" />
                <div className="delimeter"></div>
                <input id="height_in" className="input" type="number" placeholder="in*" required="yes" />
                <div className="delimeter"></div>
              </div>
            </div>
            <div className="col">
              <div className="field">
                <input id="weight" className="input" type="number" placeholder="lbs*" required="yes" />
              </div>
            </div>
          </div>
          <div className="col">
            <input className="btn goto is-link is-outlined button-like" type="submit" value="Calculate Now" style={btnColorStyle} />
          </div>
        </form>
        <div id="result"></div>
        <div className="result" style={resultBgStyle}>
          <h2 className="title"></h2>
        </div>
        <section>
          {/* Check if data is available before accessing properties */}
          <div className="ast-container bm-box bm-box_2" dangerouslySetInnerHTML={{ __html: data ? data.description2 : "Loading..." }}></div>

        </section>
      </div>
    </div>
  );
  
  
};

export default SuccessPage;
