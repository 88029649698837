import { produce } from 'immer';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SuccessPage from './SuccessPage'; // Import the new component
import React, { useRef, useEffect, useState } from 'react';
import { FieldContextProvider } from './components/form/context';
import Form from './components/form/form';
import FakeSlice from './components/slice/fakeSlice';

import './styles.css';

export default function App() {
  const [fieldState, setFieldState] = React.useState({
    title: {
      value: "Lorem ispum dolor...",
      config: {
        type: "RichText",
        placeholder: "Placeholder...",
        label: "Title",
        props: { label: "Heading" },
      },
    },
    text: {
      value:
        "<em>To call context menu click `/`</em><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ullamcorper est ut sem suscipit euismod. Suspendisse potenti. Donec cursus metus sollicitudin ex eleifend faucibus sollicitudin tempor velit. Maecenas id ultricies lectus.</p>",
      config: {
        type: "RichText2",
        placeholder: "Placeholder...",
        label: "Text",
        props: { label: "Introduction Text" },
      },
    },
    text2: {
      value:
        "<em>To call context menu click `/`</em><h2>Body Mass Index (BMI) Calculator</h2> <p>According to the WHO recommendations, there are 4 basic BMI categories for adults: <ul> <li><b>Underweight</b> - BMI is under 18.49;</li> <li><b>Normal weight</b> - BMI is between 18.5 and 24.99;</li> <li><b>Overweight</b> - BMI is between 25 and 29.99;</li> <li><b>Obesity</b> - BMI is greater than or equal to 30.</li></ul> <p>Although body mass index is not a perfect screening method, it is an easy and quick starting point for you and your weight loss doctor before going through further assessments to determine if you are a healthy weight for your height or need support to lose weight.</p>",
      config: {
        type: "RichText2",
        placeholder: "Placeholder...",
        label: "Text",
        props: { label: "Introduction Text" },
      },
    },
    btn_color: {
      value: "#000000",
      config: {
        type: "BTN_color",
        label: "CTA",
        placeholder: "Placeholder...",
        props: { label: "Calculate Now Button Color" },
      },
    },
    result_color: {
      value: "#000000",
      config: {
        type: "Result_color",
        label: "CTA",
        placeholder: "Placeholder...",
        props: { label: "Result Color" },
      },
    },
    bg: {
      value: "#ffffff",
      config: {
        type: "Select",
        label: "CTA",
        placeholder: "Placeholder...",
        props: { label: "Background Color" },
      },
    },
    img: {
      value:
        "https://cdn.dribbble.com/users/374165/screenshots/15724702/media/3e51e5cec73ae7de11dd2757d24bdf4e.png",
      config: {
        type: "Image",
        label: "Image",
        placeholder: "Placeholder...",
        props: { label: "Feature Image" },
      },
      child: {
        alt: {
          value: "Alt text",
          config: {
            type: "KeyText",
            label: "Alt text",
            placeholder: "Placeholder...",
            props: { label: "Alt text" },
          },
        },
      },
    },
  });

  const onAddAfield = (payload, id) => {
    setFieldState(
      produce((draft) => {
        draft[id] = { ...payload };
      })
    );
  };

  const onFieldChange = React.useCallback((event) => {
    event.preventDefault();
    setFieldState(
      produce((draft) => {
        draft[event.target.name].value = event.target.value;
      })
    );
  }, []);

  const onChangeImage = React.useCallback(({ name, src }) => {
    setFieldState(
      produce((draft) => {
        draft[name].value = src;
      })
    );
  }, []);

  const onRichTextUpdate = React.useCallback(({ name, html, text }) => {
    setFieldState(
      produce((draft) => {
        draft[name].value = html;
      })
    );
  }, []);

  const handleSaveBMI = async () => {
    try {
      const response = await fetch('https://bmi.doctorsgoviral.com/api/create/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          title: fieldState.title.value,
          description1: fieldState.text.value,
          description2: fieldState.text2.value,
          background_color: fieldState.bg.value,
          btn_color: fieldState.btn_color.value,
          result_color: fieldState.result_color.value
        }),
      });

      if (!response.ok) {
        // Handle error
        console.error('Error:', response.statusText);
        return;
      }

      // Assuming the API response contains the ID of the created object
      const responseData = await response.json();
      const createdObjectId = responseData.id;

      // Handle success
      // console.log('BMI Calculator saved successfully!');
      alert('BMI Calculator saved successfully!');
    
      const iframeCode = `<iframe src="https://doctorsgoviral.com/success/${createdObjectId}"></iframe>`;

      const userPrompt = prompt("Great news! Your personalized iframe code is ready to roll. 🎉\n\nCopy the code below:", iframeCode);
      
      // window.alert(`Your iframe code is <iframe src="https://doctorsgoviral.com/success/${createdObjectId}"></iframe>`);
      // Open a new window or tab with the success page
      window.open(`/success/${createdObjectId}`);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const ref = useRef(null);

  const [isExpand, setExpand] = useState(false);
  const handleExpand = () => {
    setExpand(!isExpand);
  };

  return (
    <Router>
      <FieldContextProvider
        form={fieldState}
        onChange={onFieldChange}
        addField={onAddAfield}
        onRichTextUpdate={onRichTextUpdate}
        onChangeImage={onChangeImage}
      >
        <Routes>
          <Route path="/success/:id" element={<SuccessPage />} />
          <Route
            path="/"
            element={
              <div className="wrap">
                <div className="leftView">
                  <div className="controle"></div>
                  <div className="preview">
                    <FakeSlice />
                  </div>
                </div>

                <div ref={ref} data-expand={isExpand} className="sidebar">
                  <div className="controle">
                    <div></div>
                    <button onClick={handleSaveBMI} className="primary">
                      Save BMI Calculator
                    </button>
                  </div>
                  <Form />
                </div>
              </div>
            }
          />
        </Routes>
      </FieldContextProvider>
    </Router>
  );
}