import React from "react";

export const FieldContext = React.createContext({
  form: {},
  onChange: {},
  addField: {},
  onRichTextUpdate: {},
  onChangeImage: {},
  onRichTextUpdate1: {},
});

export function FieldContextProvider(props) {
  const value = React.useMemo(
    () => ({
      form: props.form,
      onChange: props.onChange,
      addField: props.addField,
      onRichTextUpdate: props.onRichTextUpdate,
      onChangeImage: props.onChangeImage,
      onRichTextUpdate1: props.onRichTextUpdate,
    }),
    [props]
  );

  return (
    <FieldContext.Provider value={value}>
      {props.children}
    </FieldContext.Provider>
  );
}
