import React from "react";
import "./simpleField.css";

export default function Field(props) {
  return (
    <div className="field-container">
      <label>{props.label}</label>
      <select
        className="selectField"
        name={props.name}
        type={props.type}
        onChange={props.onChange}
        value={props.value}
      >
        <option value="#000">Dark</option>
        <option value="#696969">Grey</option>
        <option value="#1E90FF">Blue</option>
        <option value="#B22222">Red</option>
        <option value="#EEE8AA">Yellow</option>
      </select>
    </div>
  );
}
