import React from "react";
import { FieldContext } from "./context";
import "./form.css";
import Select from "./fields/selectField";
import SimpleField from "./fields/simpleField";
import Textarea from "./fields/textArea";
import RichText from "./fields/richText";
import ImageField from "./fields/Image";
import { Modal } from "react-responsive-modal";

const FIELDS = {
  Select: Select,
  KeyText: SimpleField,
  RichText: Textarea,
  RichText2: RichText,
  BTN_color: SimpleField,
  Result_color: SimpleField,
  Image: ImageField
};

export default function Form() {
  const context = React.useContext(FieldContext);
  const [open, setOpen] = React.useState(false);
  function PrioritizeModal({ onClose, open }) {
    return (
      <Modal open={open} onClose={onClose} center>
        <header>Vote for your favorite fields</header>
        <section className="fields">
          <div className="field">
            <input type="checkbox" id="1" name="1" />
            <label htmlFor="1">Link</label>
          </div>

          <div className="field">
            <input type="checkbox" id="2" name="2" />
            <label htmlFor="2">Content Relationship</label>
          </div>

          <div className="field">
            <input type="checkbox" id="3" name="3" />
            <label htmlFor="3">Boolean</label>
          </div>

          <div className="field">
            <input type="checkbox" id="4" name="4" />
            <label htmlFor="4">Group</label>
          </div>

          <div className="field">
            <input type="checkbox" id="5" name="5" />
            <label htmlFor="5">Number</label>
          </div>

          <div className="field">
            <input type="checkbox" id="6" name="6" />
            <label htmlFor="6">Link to Media</label>
          </div>

          <div className="field">
            <input type="checkbox" id="7" name="7" />
            <label htmlFor="7">Date</label>
          </div>

          <div className="field">
            <input type="checkbox" id="8" name="8" />
            <label htmlFor="8">Timestamp</label>
          </div>

          <div className="field">
            <input type="checkbox" id="9" name="9" />
            <label htmlFor="9">Integration Fields</label>
          </div>

          <div className="field">
            <input type="checkbox" id="10" name="10" />
            <label htmlFor="10">Embed</label>
          </div>

          <div className="field">
            <input type="checkbox" id="11" name="11" />
            <label htmlFor="11">Color</label>
          </div>

          <div className="field">
            <input type="checkbox" id="12" name="12" />
            <label htmlFor="12">Geopoint</label>
          </div>
          <button onClick={onClose} className="primary">
            Vote
          </button>
        </section>
      </Modal>
    );
  }

  return (
    <div className="box">
      <header>Mocks editor BETA</header>
{/* 
      <p className="info">
        We currently support Rich Text, Image, Select, Key Text & Title fields.{" "}
        <a onClick={() => setOpen(true)}>
          Let us know what fields you think we should add next.
        </a>
      </p>
      <PrioritizeModal open={open} onClose={() => setOpen(false)} /> */}

      <div className="fieldSet">
        {Object.keys(context.form).map((key, i) => {
          const type = context.form[key].config.type;
          const value = context.form[key].value;
          const child = context.form[key]?.child || {};
          const label = context.form[key].config.props.label;
          const Field = FIELDS[type];
          return (
            <Field
              label={label}
              child={child}
              key={key + i}
              value={value}
              onChange={context.onChange}
              onRichTextUpdate={context.onRichTextUpdate}
              // onChangeImage={context.onChangeImage}
              name={key}
            />
          );
        })}
      </div>
    </div>
  );
}
